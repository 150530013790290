import { createActions } from '../utils';

let resources = [
  'provinces',
  'birth places',
  'regencies',
  'districts',
  'villages',
  'postal codes',
  'order and plan',
  'banks',
  'payment methods',
  'navigation items',
  'location list',
  'related articles',
  'calculator articles',
  'payment link status',
  'payment link',
  'salesforce token',
],
otherActions = [
  'submit leads',
  'submiting leads',
  'submitted leads',
  'error submitting leads',
  'reset leads',

  'submit financial checkup',
  'submitting financial checkup',
  'submitted financial checkup',
  'error submitting financial checkup',

  'error loading order and plan',
  'error loading banks',
  'error loading payment methods',

  'updating leads',
  'updated leads',
  'error updating leads',

  'error loading navigation items',
  'error loading location list',
  'reset location list',

  'reset lead response',

  'update financial checkup',
  'updating financial checkup',
  'updated financial checkup',
  'error updating financial checkup',
  'error loading latest articles',
  'error loading related articles',
  'error loading calculator articles',

  'upload files',
  'upload files start',
  'upload files progress',
  'upload files success',
  'upload files failed',

  'get latest articles',
  'error loading payment link status',

  'error loading salesforce token',
];

export default createActions(resources, otherActions);
